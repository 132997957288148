import loadable from '@loadable/component';
import React, { useContext } from 'react';
import { Route, Switch, Link, useLocation, useHistory } from 'react-router-dom';

import { ConfigContext, ProjectContext } from '@core/context';
import useEventListener from '@core/hooks/useEventListener';
import useFeatureOptions from '@core/hooks/useFeatureOptions';
import useRoute from '@core/hooks/useRoute';
import useSentry from '@core/hooks/useSentry';
import useUserPermissions from '@core/hooks/useUserPermissions';
import { useSuperHubStore } from '@core/store';

import EmptyState from '@routes/SuperHub/components/EmptyState';

import Button from '@ui/Button';

import classes from './style.module.scss';

const DiscussList = loadable(() => import('./List'), { ssr: true });
const DiscussPage = loadable(() => import('./Page'), { ssr: true });
const DiscussNew = loadable(() => import('./New'), { ssr: true });

const Back = ({ children }) => {
  const history = useHistory();
  const { state = {} } = useLocation();
  let Tag = Link;
  let attrs = { to: '/discuss' };
  if ('fromDiscussionForum' in state && state.fromChangelogList) {
    Tag = Button;
    attrs = { onClick: () => history.goBack() };
  }
  return (
    <Tag {...attrs} className={`Button Button_md ${classes['Discuss-back']}`} target="_self">
      {children}
    </Tag>
  );
};

const DiscussionForum = props => {
  /* Asynchronously fetch data from the
   * matching Express route on navigate
   */
  // TODO: add loading state in here
  const { state } = useRoute(props);

  const { project } = useContext(ProjectContext);
  const { domainFull } = useContext(ConfigContext);
  const { flags, modules } = project;
  const featureOptions = useFeatureOptions(modules);
  const { isLoggedIn } = useUserPermissions();

  const { pathname } = useLocation();
  const showHeader = pathname !== '/discuss-new';
  const onPostPage = pathname !== '/discuss';

  const onError = useSentry('discussions');
  useEventListener('error', onError);

  const LinkTag = flags.disableAnonForum && !isLoggedIn ? 'a' : Link;
  const createUrl = `${domainFull}/to/${project.subdomain}?redirect=/discuss-new`;
  const createPath = '/discuss-new';

  const isSuperHub = useSuperHubStore(s => s.isSuperHub);
  const isEmptyState = pathname === '/discuss' && !state.discussions?.length;

  // For superhub only, render a different empty state when no discussion exist.
  if (isSuperHub && isEmptyState) {
    const actionUrl = LinkTag === 'a' ? createUrl : createPath;
    return <EmptyState action={actionUrl} section="discussion" />;
  }

  return (
    <main className={`${classes.Discuss} rm-Discuss`} id="content">
      {!!showHeader && (
        <header className={classes['Discuss-header']}>
          <div className={classes['Discuss-header_top']}>
            <div>
              <h1 className={classes['Discuss-header_title']}>{featureOptions.discuss}</h1>
            </div>
            {!!((state.discussions && state.discussions.length > 0) || onPostPage) && (
              <LinkTag
                className={`Button Button_md ${classes['Discuss-question_button']}`}
                href={createUrl}
                target="_self"
                to={createPath}
              >
                Ask a Question
              </LinkTag>
            )}
          </div>
          {!!onPostPage && (
            <Back>
              <div className={`fa fa-chevron-circle-left fa_right ${classes['Discuss-back-icon']}`}></div>
              Back to All
            </Back>
          )}
        </header>
      )}
      <Switch>
        <Route path="(/discuss/new|/discuss-new)">
          <DiscussNew {...state} />
        </Route>
        <Route path="/discuss/:slug">
          <DiscussPage {...state} />
        </Route>
        <Route exact path="/discuss">
          <DiscussList {...state} />
        </Route>
      </Switch>
    </main>
  );
};

export default DiscussionForum;
